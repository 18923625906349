import React from "react"
import Layout from "../components/Layout"
import { Text, Box } from "rebass"

export default function Thanks() {
  return (
    <Layout>
      <Box textAlign="center" my={64}>
        <Text variant="content">Thank you for getting in contact.</Text>
        <Text variant="content">I will get back to you shortly.</Text>
      </Box>
    </Layout>
  )
}
